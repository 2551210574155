import styled from 'styled-components'

export const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

export const WrappedText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Snack = styled.span`
  padding: 2px 8px;
  background-color: #ddd;
  color: #999;
  border-radius: 8px;
`

export const IconBtn = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ circle }) => (circle ? '50%' : '4px')};
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`

export const TextBtn = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
