import React from 'react'

const Home = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <span>AIYA Chat App</span>
    </div>
  )
}

export default Home
