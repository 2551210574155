import React from 'react'
import { Button, Icon } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import ChatScreen from './ChatScreen'
import AIYA_LOGO from '../../assets/images/aiya-logo-blue.png'
import ImageRenderer from '../../components/ImageRenderer'
import ChatSidebar from '../../components/ChatSidebar'
import Loading from '../../components/Loading'

const ALL_TAGS = gql`
  query allTags($botId: ID!) {
    allTags(bot_id: $botId)
  }
`

const TabletLayout = props => {
  const botId = props.match.params.botId
  const { data: allTagsData, loading, error: allTagsError } = useQuery(ALL_TAGS, {
    variables: { botId },
  })
  if (loading) {
    return <Loading />
  }
  if (allTagsError) {
    console.error(allTagsError)
  }
  const allTags = (allTagsData && allTagsData.allTags) || []

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          height: 60,
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          display: 'flex',
          width: '100vw',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px 0 32px',
        }}
      >
        <ImageRenderer height={48} src={AIYA_LOGO} alt="logo" />
        <Button>ออกจากระบบ</Button>
      </div>
      <div style={{ display: 'flex', height: 'calc(100% - 60px)' }}>
        <div style={{ width: 320, height: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.15)' }}>
          <ChatSidebar allTags={allTags} />
        </div>
        <div style={{ width: 'calc(100% - 320px)', position: 'relative' }}>
          <Switch>
            <Redirect from="/:botId/chat" to="/:botId/chat/list" exact />
            <Route
              path="/:botId/chat/list"
              render={() => (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '12em',
                    color: 'rgba(0, 0, 0, 0.25)',
                  }}
                >
                  <Icon type="message" />
                </div>
              )}
            />
            <Route
              path="/:botId/chat/:chatId"
              render={props => <ChatScreen {...props} allTags={allTags} />}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default TabletLayout
