import React from 'react'
import PT from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Input } from 'antd'

import StickerPanel from '../StickerPanel'
import { IconBtn } from '../../../styled'

const IconButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`

const LineComposerBar = ({
  mutateSendMessage,
  mutateFileUpload,
  botId,
  chatId,
  setStickerState,
  stickerState,
}) => {
  const admin = localStorage.getItem('user_id')
  const [inputMessage, setInputMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const uploadInput = React.createRef()

  const openUpload = () => {
    setStickerState(false)
    uploadInput.current.click()
  }

  const sendMessage = async () => {
    if (!inputMessage) {
      return
    }
    setLoading(true)
    try {
      await mutateSendMessage({
        variables: {
          botId,
          admin: admin,
          chatId,
          messages: [
            {
              type: 'text',
              text: inputMessage,
            },
          ],
        },
      })
    } catch (err) {
      console.error(err.message)
    }
    setLoading(false)
    setInputMessage('')
  }

  const handleUploadFile = async e => {
    const file = e.target.files[0]
    setLoading(true)
    try {
      const { data } = await mutateFileUpload({ variables: { file, botId } })
      const uploaded = (data && data.fileUpload) || {}

      if (['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
        await mutateSendMessage({
          variables: {
            botId,
            admin: admin,
            chatId,
            messages: [
              {
                type: 'image',
                originalContentUrl: uploaded.url,
                previewImageUrl: uploaded.url,
              },
            ],
          },
        })
      } else {
        await mutateSendMessage({
          variables: {
            botId,
            admin: admin,
            chatId,
            messages: [
              {
                type: 'custom',
                template: 'file',
                message: {
                  type: 'flex',
                  altText: 'Sent a file.',
                  contents: {
                    type: 'bubble',
                    direction: 'ltr',
                    body: {
                      type: 'box',
                      layout: 'vertical',
                      action: {
                        type: 'uri',
                        label: 'open file',
                        uri: uploaded.url,
                      },
                      contents: [
                        {
                          type: 'box',
                          layout: 'vertical',
                          contents: [
                            {
                              type: 'text',
                              text: uploaded.filename,
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
                data: {
                  ...uploaded,
                },
              },
            ],
          },
        })
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const handleStickerClick = async data => {
    setLoading(true)
    try {
      await mutateSendMessage({
        variables: {
          botId,
          admin: admin,
          chatId,
          messages: [
            {
              type: 'sticker',
              ...data,
            },
          ],
        },
      })
      setStickerState(false)
    } catch (err) {
      console.error(err.message)
    }
    setLoading(false)
  }

  return (
    <div
      style={{
        position: 'absolute',
        bottom: stickerState ? 0 : -320,
        left: 0,
        right: 0,
        transition: 'all 0.35s',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          padding: '8px 16px',
          borderTop: '1px solid rgba(0, 0, 0, 0.15)',
          display: 'flex',
        }}
      >
        <Input.TextArea
          value={inputMessage}
          placeholder="Type a message.. (Shift+Enter or cmd+Enter to send)"
          onChange={e => setInputMessage(e.target.value)}
          onPressEnter={e => (e.metaKey || e.shiftKey) && sendMessage()}
          onFocus={() => setStickerState(false)}
          disabled={loading}
          autosize={{ minRows: 1, maxRows: 3 }}
          style={{ resize: 'none' }}
        />
        <IconBtn onClick={sendMessage} style={{ marginLeft: 8 }}>
          <IconButton icon="paper-plane" />
        </IconBtn>
        <IconBtn onClick={() => setStickerState(!stickerState)}>
          <FontAwesomeIcon icon={['far', 'smile']} />
        </IconBtn>
        <IconBtn onClick={openUpload}>
          <FontAwesomeIcon icon="paperclip" />
        </IconBtn>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={uploadInput}
          onChange={handleUploadFile}
        />
      </div>
      <div style={{ width: '100%', height: 320 }}>
        <StickerPanel onSelect={handleStickerClick} loading={loading} />
      </div>
    </div>
  )
}

LineComposerBar.propTypes = {
  botId: PT.string,
  chatId: PT.string,
  mutateSendMessage: PT.func,
}

LineComposerBar.defaultProps = {
  mutateSendMessage: () => {},
}

export default LineComposerBar
