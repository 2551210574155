export const getObjectValueByKeyStr = (obj, key) => {
  return [obj].concat(key.split('.')).reduce((a, b) => a[b])
}

export const mergeArr = (arr1, arr2, key) => {
  let tmp = [...arr1]
  if (key) {
    let keyTmp = tmp.map(t => getObjectValueByKeyStr(t, key))
    arr2.forEach(item => {
      if (!keyTmp.includes(getObjectValueByKeyStr(item, key))) {
        tmp.push(item)
        keyTmp.push(item[key])
      }
    })
  } else {
    arr2.forEach(item => {
      if (!tmp.includes(item)) {
        tmp.push(item)
      }
    })
  }
  return tmp
}

export const qs2obj = qs => {
  if (!qs) {
    return {}
  }
  let tmpStr = qs.replace('?', '')
  let tmpArr = tmpStr.split('&')
  let tmpObj = {}
  tmpArr.forEach(item => {
    const field = item.split('=')
    tmpObj = { ...tmpObj, [field[0]]: field[1] }
  })
  return tmpObj
}

export const obj2qs = obj => {
  let tmpArr = Object.keys(obj).map(key => `${key}=${obj[key]}`)
  let tmpStr = '?' + tmpArr.join('&')
  return tmpStr
}

export function hexToRgbA(hex) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
  }
  throw new Error('Bad Hex')
}
