import React from 'react'
import PT from 'prop-types'

import AIYA_BOT_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../../components/ImageRenderer'
import Fb from '../../../service/_facebookClient'

const PageItem = ({ data, selected, onSelect }) => {
  const [pageImage, setPageImage] = React.useState()

  React.useEffect(() => {
    if (window.FB) {
      Fb.getPagePicture(data.id, res => {
        setPageImage((res.data || {}).url)
      })
    }
  }, [])

  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderColor: selected ? '#2592EF' : 'rgba(0, 0, 0, 0.1)',
        borderRadius: 8,
        padding: '8px 16px',
        marginBottom: 8,
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={onSelect}
    >
      <ImageRenderer
        src={pageImage}
        alt={data.id || '-'}
        errorImg={AIYA_BOT_BLANK}
        style={{ width: 64, height: 64, borderRadius: '50%', marginRight: 16 }}
      />
      <div>
        <h3 style={{ color: selected ? '#2592EF' : 'inherit', fontWeight: 600, marginBottom: 0 }}>
          {data.name || '-'}
        </h3>
        <small style={{ color: '#b7b7b7', fontWeight: 500 }}>ID: {data.id || '-'}</small>
      </div>
    </div>
  )
}

PageItem.propTypes = {
  data: PT.shape({
    id: PT.string,
    name: PT.string,
    access_token: PT.string,
  }),
  selected: PT.bool,
  onSelect: PT.func,
}

PageItem.defaultProps = {
  data: {},
  onSelect: () => {},
}

export default PageItem
