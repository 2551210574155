import React from 'react'
import PT from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Input } from 'antd'

import { IconBtn } from '../../../styled'

const IconButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`

const FacebookComposerBar = ({ mutateSendMessage, mutateFileUpload, botId, chatId }) => {
  const admin = localStorage.getItem('user_id')
  const [inputMessage, setInputMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const uploadInput = React.createRef()

  const openUpload = () => {
    uploadInput.current.click()
  }

  const handleUploadFile = async e => {
    const file = e.target.files[0]
    setLoading(true)
    try {
      const { data } = await mutateFileUpload({ variables: { file, botId } })
      const uploaded = (data && data.fileUpload) || {}

      const uploadType = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
        ? 'image'
        : 'file'

      await mutateSendMessage({
        variables: {
          botId,
          admin,
          chatId,
          messages: [
            {
              attachment: {
                type: uploadType,
                payload: {
                  url: uploaded.url,
                },
              },
            },
          ],
        },
      })
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const sendMessage = async () => {
    if (!inputMessage) {
      return
    }
    setLoading(true)
    try {
      await mutateSendMessage({
        variables: {
          botId,
          admin: admin,
          chatId,
          messages: [
            {
              text: inputMessage,
            },
          ],
        },
      })
    } catch (err) {
      console.error(err.message)
    }
    setLoading(false)
    setInputMessage('')
  }

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        transition: 'all 0.35s',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          padding: '8px 16px',
          borderTop: '1px solid rgba(0, 0, 0, 0.15)',
          display: 'flex',
        }}
      >
        <Input.TextArea
          value={inputMessage}
          placeholder="Type a message.. (Shift+Enter or cmd+Enter to send)"
          onChange={e => setInputMessage(e.target.value)}
          onPressEnter={e => (e.metaKey || e.shiftKey) && sendMessage()}
          disabled={loading}
          autosize={{ minRows: 1, maxRows: 3 }}
          style={{ resize: 'none' }}
        />
        <IconBtn onClick={sendMessage} style={{ marginLeft: 8 }}>
          <IconButton icon="paper-plane" />
        </IconBtn>
        <IconBtn onClick={openUpload}>
          <IconButton icon="paperclip" />
        </IconBtn>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={uploadInput}
          onChange={handleUploadFile}
        />
      </div>
    </div>
  )
}

FacebookComposerBar.propTypes = {
  botId: PT.string,
  chatId: PT.string,
  mutateSendMessage: PT.func,
  mutateFileUpload: PT.func,
}

FacebookComposerBar.defaultProps = {
  mutateSendMessage: () => {},
  mutateFileUpload: () => {},
}

export default FacebookComposerBar
