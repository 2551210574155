import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'
import 'antd/dist/antd.css'
import './index.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPaperPlane,
  faComments,
  faCommentDots,
  faCommentDollar,
  faCommentSlash,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons'
import { faSmile } from '@fortawesome/free-regular-svg-icons'

import { client } from './api/apollo'
import App from './App'

library.add([
  faPaperPlane,
  faComments,
  faCommentDots,
  faCommentDollar,
  faCommentSlash,
  faPaperclip,
  faSmile,
])

const container = document.getElementById('root')
ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <ApolloProvider client={client}>
      <ApolloHookProvider client={client}>
        <App />
      </ApolloHookProvider>
    </ApolloProvider>
  </BrowserRouter>,
  container,
)
