import React from 'react'
import PT from 'prop-types'
import { Button, Icon } from 'antd'

import FB_LOGO from '../../../assets/images/Logo_FB.png'

const PleaseConnect = ({ onConnectClick }) => {
  return (
    <div
      style={{
        height: 'calc(100% - 60px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 8,
        textAlign: 'center',
      }}
    >
      <div style={{ position: 'relative', marginBottom: 16 }}>
        <img src={FB_LOGO} alt="fb-logo" width={80} height={80} />
        <Icon
          type="warning"
          theme="filled"
          style={{ color: '#DB1540', position: 'absolute', right: -8, top: -8, fontSize: 24 }}
        />
      </div>
      <span style={{ color: '#a00326', fontWeight: 600, fontSize: 'large', marginBottom: 24 }}>
        ยังไม่เชื่อมต่อ
      </span>
      <span>ระบบ ACRM ของคุณยังไม่ถูกเชื่อมต่อ Facebook</span>
      <span>กดปุ่มเชื่อมต่อด้านล่างเพื่อทำการเชื่อมต่อไปยังเพจของคุณ</span>
      <Button type="primary" size="large" style={{ marginTop: 24 }} onClick={onConnectClick}>
        เชื่อมต่อไปยัง Facebook
      </Button>
    </div>
  )
}

PleaseConnect.propTypes = {
  onConnectClick: PT.func,
}

export default PleaseConnect
