import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import ChatArea from '../../components/ChatArea'
import ChatInfo from '../../components/ChatInfo'
import Loading from '../../components/Loading'
import { Flex } from '../../styled'

const GET_FRIEND = gql`
  query getChat($chatId: ID!, $botId: ID!) {
    getChat(chat_id: $chatId, bot_id: $botId) {
      id
      channel_id
      social_id
      full_name
      picture_url
      platform
      tags
      bc_data
      blocked
      creation
      status
      bot_enabled
      wisible {
        id
        url
        enabled
      }
    }
  }
`

const UPDATE_CHAT = gql`
  mutation updateChat($botId: String!, $chatId: ID!, $input: ChatInput!) {
    updateChat(input: $input, chat_id: $chatId, bot_id: $botId)
  }
`

const SUBSCRIBE_FRIEND = gql`
  subscription onFriendChange($botId: ID!, $uuid: String) {
    onFriendChanged(bot_id: $botId, uuid: $uuid)
  }
`

const SUBSCRIBE_BOT_ENABLED = gql`
  subscription onMuteChanged($botId: ID!, $uuid: ID) {
    onMuteChanged(bot_id: $botId, uuid: $uuid)
  }
`

const ChatContainer = ({ botId, match }) => {
  const chatId = match.params.chatId
  const { data, error, loading, subscribeToMore } = useQuery(GET_FRIEND, {
    variables: { chatId, botId },
    fetchPolicy: 'cache-and-network',
  })
  const [updateChat] = useMutation(UPDATE_CHAT)

  const [showInfo, setInfoState] = React.useState(true)

  console.log({ botId, chatId })

  React.useEffect(() => {
    let unsubBotEnabled = () => {}
    let unsubFriend = () => {}
    if (!loading) {
      const subscribeBotEnabled = () =>
        subscribeToMore({
          document: SUBSCRIBE_BOT_ENABLED,
          variables: { botId, uuid: chatId },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const newData = subscriptionData.data.onMuteChanged || {}
            return {
              ...prev,
              getChat: { ...prev.getChat, bot_enabled: !!newData.bot_enabled },
            }
          },
        })
      const subscribeFriend = () =>
        subscribeToMore({
          document: SUBSCRIBE_FRIEND,
          variables: { botId, uuid: chatId },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const newData = subscriptionData.data.onFriendChanged || {}
            if (newData.op === 'CREATE') {
              return prev
            }
            console.log(newData)
            const tmp = {
              ...newData,
              doctype: undefined,
              op: undefined,
            }
            return { ...prev, getChat: { ...prev.getChat, ...tmp } }
          },
        })

      unsubBotEnabled = subscribeBotEnabled()
      unsubFriend = subscribeFriend()
    }
    return () => {
      unsubBotEnabled()
      unsubFriend()
    }
  }, [loading, botId, chatId, subscribeToMore])

  if (loading) return <Loading />
  if (error) {
    console.error(error.message)
  }

  const friendData = (data && data.getChat) || {}

  return (
    <Flex
      style={{
        width: '100%',
        height: '100%',
        flexFlow: 'row nowrap',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: showInfo ? 'calc(100% - 280px)' : '100%',
          height: '100%',
          transition: '0.35s all',
          position: 'relative',
        }}
      >
        <ChatArea
          toggleChatInfo={() => setInfoState(!showInfo)}
          friendData={friendData}
          mutateUpdate={updateChat}
          botId={botId}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: showInfo ? 0 : -320,
          transition: '0.35s all',
          width: '280px',
          height: '100%',
          borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
        }}
      >
        <ChatInfo botId={botId} friendData={friendData} mutateUpdate={updateChat} />
      </div>
    </Flex>
  )
}

export default ChatContainer
