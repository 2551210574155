import React from 'react'
import { withApollo } from 'react-apollo'

import Loading from './components/Loading'

const Logout = ({ client }) => {
  localStorage.removeItem('user_id')
  localStorage.removeItem('access_token')
  client.resetStore()
  window.location = 'https://manager.aiya.ai/logout'
  return <Loading />
}

export default withApollo(Logout)
