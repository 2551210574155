import React from 'react'
import { Modal, Icon, Select, message, Input, InputNumber } from 'antd'
import PT from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import customProps from '../../constants/proptypes'
import BotContext from '../../context/Bot'
import { Flex, IconBtn } from '../../styled'
import Loading from '../Loading'
import InputField from '../InputField'
import ProfileInfo from './ProfileInfo'

const ALL_TAGS = gql`
  query allTags($botId: ID!) {
    allTags(bot_id: $botId)
  }
`

const CONNECT_WISIBLE = gql`
  mutation connectWisible($botId: String!, $chatId: String!, $input: WisibleDealInput!) {
    connectWisible(bot_id: $botId, chat_id: $chatId, input: $input) {
      id
      wisible {
        id
        url
        enabled
      }
    }
  }
`

class ChatInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input: {},
      loading: false,
      tagModalState: false,
      wisible: {},
      wisibleModalState: false,
    }
  }

  updateFriend = async input => {
    const { mutateUpdate, friendData, botId } = this.props
    try {
      this.setState({ loading: true })
      const tmp = {
        full_name: friendData.full_name,
        picture_url: friendData.picture_url,
        tags: friendData.tags,
        status: friendData.status,
        ...input,
      }
      await mutateUpdate({
        variables: { input: tmp, chatId: friendData.id, botId },
      })
      message.success('บันทึก')
    } catch (err) {
      console.error(err.message)
    }
    this.setState({ loading: false })
    this.closeModal()
  }

  openTagModal = () => {
    const { friendData } = this.props
    this.setState({ tagModalState: true, input: { tags: friendData.tags } })
  }

  openWisibleModal = () => {
    const { friendData } = this.props
    this.setState({
      wisibleModalState: true,
      wisible: {
        contact: friendData.full_name,
      },
    })
  }

  closeModal = () => {
    this.setState({
      tagModalState: false,
      wisibleModalState: false,
      wisible: {},
      input: {},
    })
  }

  handleConnectWisible = async () => {
    const { friendData, connectWisible, botId } = this.props
    const { wisible } = this.state
    if (!(wisible.contact && wisible.company && wisible.phone)) {
      message.error('กรุณากรอกข้อมูลในช่องที่มี * สีแดงให้ครบ')
      return
    }
    const input = {
      refId: friendData.id,
      contact: wisible.contact,
      company: wisible.company,
      phone: wisible.phone,
      value: wisible.value,
      labels: wisible.labels,
      aiyaTag: friendData.tags,
    }
    try {
      await connectWisible({ variables: { botId, chatId: friendData.id, input } })
      message.success('เชื่อมต่อสำเร็จ')
      this.closeModal()
    } catch (err) {
      console.error(err)
    }
  }

  handleWisibleClick = () => {
    const { friendData } = this.props
    if (friendData.wisible && friendData.wisible.enabled) {
      window.open(friendData.wisible.url, '_blank')
    } else {
      this.openWisibleModal()
    }
  }

  handleWisibleInputChange = item => {
    this.setState({ wisible: { ...this.state.wisible, ...item } })
  }

  render() {
    const { friendData, allTags, bot } = this.props
    const { tagModalState, wisibleModalState, input, wisible, loading } = this.state
    return (
      <div style={{ marginBottom: 16 }}>
        <Flex style={{ width: '100%', justifyContent: 'flex-end', padding: 8 }}>
          <IconBtn circle>
            <Icon type="more" />
          </IconBtn>
        </Flex>
        <ProfileInfo
          friendData={friendData}
          wisibleEnabled={bot.wisible_enabled}
          openTagModal={this.openTagModal}
          onWisibleClick={this.handleWisibleClick}
          updateFriend={this.updateFriend}
          loading={loading}
        />

        <Modal
          visible={tagModalState}
          title={`แท็กของ ${friendData.full_name}`}
          cancelText="ยกเลิก"
          okText="บันทึก"
          onCancel={this.closeModal}
          onOk={() => this.updateFriend({ tags: input.tags })}
          confirmLoading={loading}
          destroyOnClose
        >
          <Select
            defaultValue={input.tags}
            mode="tags"
            style={{ width: '100%' }}
            placeholder="-- เลือกแท็กจากแท็กที่มีอยู่ หรือ เพิ่มแท็กใหม่ --"
            onChange={e => this.setState({ input: { ...input, tags: e } })}
            autoFocus
            defaultOpen
          >
            {allTags.map(t => (
              <Select.Option key={t}>{t}</Select.Option>
            ))}
          </Select>
        </Modal>

        {bot.wisible_enabled && (
          <Modal
            visible={wisibleModalState}
            title="เชื่อมต่อกับ Wisible"
            cancelText="ยกเลิก"
            okText="เชื่อมต่อ"
            confirmLoading={loading}
            onCancel={this.closeModal}
            onOk={this.handleConnectWisible}
            destroyOnClose
          >
            <InputField title="Contact" isRequired>
              <Input
                value={wisible.contact}
                onChange={e => this.handleWisibleInputChange({ contact: e.target.value })}
              />
            </InputField>
            <InputField title="Company" isRequired>
              <Input
                value={wisible.company}
                onChange={e => this.handleWisibleInputChange({ company: e.target.value })}
              />
            </InputField>
            <InputField title="Phone" isRequired>
              <Input
                value={wisible.phone}
                onChange={e => this.handleWisibleInputChange({ phone: e.target.value })}
              />
            </InputField>
            <InputField title="Value">
              <InputNumber
                value={wisible.value}
                onChange={val => this.handleWisibleInputChange({ value: val })}
              />
            </InputField>
            <InputField title="Labels">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                value={wisible.labels}
                onChange={val => this.handleWisibleInputChange({ labels: val })}
              />
            </InputField>
          </Modal>
        )}
      </div>
    )
  }
}

ChatInfo.propTypes = {
  botId: PT.string,
  friendData: customProps.CHAT_PT,
  mutateUpdate: PT.func,
}

ChatInfo.defaultProps = {
  friendData: {},
  mutateUpdate: () => {},
}

export default props => {
  const { data, loading, error } = useQuery(ALL_TAGS, { variables: { botId: props.botId } })
  const [connectWisible] = useMutation(CONNECT_WISIBLE)

  const bot = React.useContext(BotContext)

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
  }

  const allTags = (data && data.allTags) || []

  return <ChatInfo {...props} bot={bot} allTags={allTags} connectWisible={connectWisible} />
}
