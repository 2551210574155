import React from 'react'
import { Drawer } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Loading from '../../components/Loading'
import ChatArea from '../../components/ChatArea'
import ChatInfo from '../../components/ChatInfo'

const GET_FRIEND = gql`
  query getChat($chatId: ID!, $botId: ID!) {
    getChat(chat_id: $chatId, bot_id: $botId) {
      id
      channel_id
      social_id
      full_name
      picture_url
      platform
      tags
      bc_data
      blocked
      creation
      status
      bot_enabled
      wisible {
        id
        url
        enabled
      }
    }
  }
`

const UPDATE_CHAT = gql`
  mutation updateChat($botId: String!, $chatId: ID!, $input: ChatUpdate!) {
    updateChat(input: $input, chat_id: $chatId, bot_id: $botId)
  }
`

const SUBSCRIBE_FRIEND = gql`
  subscription onFriendChange($botId: ID!) {
    onFriendChanged(bot_id: $botId)
  }
`

const SUBSCRIBE_BOT_ENABLED = gql`
  subscription onMuteChanged($botId: ID!, $uuid: ID) {
    onMuteChanged(bot_id: $botId, uuid: $uuid)
  }
`

const MobileChatContainer = ({ match, history }) => {
  const botId = match.params.botId
  const chatId = match.params.chatId
  const [showInfo, setInfoState] = React.useState(false)
  const { data, loading, error, subscribeToMore } = useQuery(GET_FRIEND, {
    variables: { chatId, botId },
    fetchPolicy: 'cache-and-network',
  })
  const [updateChat] = useMutation(UPDATE_CHAT)

  React.useEffect(() => {
    let unsubBotEnabled = () => {}
    let unsubFriend = () => {}
    if (!loading) {
      const subscribeBotEnabled = () =>
        subscribeToMore({
          document: SUBSCRIBE_BOT_ENABLED,
          variables: { botId, uuid: chatId },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const newData = subscriptionData.data.onMuteChanged || {}
            return {
              ...prev,
              getChat: { ...prev.getChat, bot_enabled: !!newData.bot_enabled },
            }
          },
        })
      const subscribeFriend = () =>
        subscribeToMore({
          document: SUBSCRIBE_FRIEND,
          variables: { botId },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const newData = subscriptionData.data.onFriendChanged || {}
            if (newData.op === 'CREATE') {
              return prev
            }
            const tmp = {
              ...newData,
              doctype: undefined,
              op: undefined,
            }
            return { ...prev, getChat: { ...prev.getChat, ...tmp } }
          },
        })

      unsubBotEnabled = subscribeBotEnabled()
      unsubFriend = subscribeFriend()
    }
    return () => {
      unsubBotEnabled()
      unsubFriend()
    }
  }, [loading, botId, chatId, subscribeToMore])

  if (loading) return <Loading />
  if (error) {
    console.error(error.message)
  }

  const friendData = (data && data.getChat) || {}

  return (
    <>
      <div style={{ height: '100%' }}>
        <ChatArea
          toggleChatInfo={() => setInfoState(!showInfo)}
          friendData={friendData}
          mutateUpdate={updateChat}
          botId={botId}
          onBack={() => history.goBack()}
        />
      </div>
      <Drawer
        width={320}
        title={`ข้อมูลของ ${friendData.full_name}`}
        placement="right"
        onClose={() => setInfoState(false)}
        visible={showInfo}
        bodyStyle={{ padding: 0, height: '100%', overflow: 'auto' }}
      >
        <ChatInfo botId={botId} friendData={friendData} mutateUpdate={updateChat} />
      </Drawer>
    </>
  )
}

export default MobileChatContainer
