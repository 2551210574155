import React from 'react'
import PT from 'prop-types'

import PlatformItem from './PlatformItem'

// import FB_BLUE from '../../assets/images/logo/facebook_64_blue.png'
// import FB_GREY from '../../assets/images/logo/facebook_64_grey.png'
// import IG_PURPLE from '../../assets/images/logo/instagram_64_purple.png'
// import IG_GREY from '../../assets/images/logo/instagram_64_grey.png'
import LINE_GREEN from '../../assets/images/logo/line_64_green.png'
import LINE_GREY from '../../assets/images/logo/line_64_grey.png'
import MG_BLUE from '../../assets/images/logo/messenger_64_blue.png'
import MG_GREY from '../../assets/images/logo/messenger_64_grey.png'
import MAIL_BLUE from '../../assets/images/logo/mail_64_blue.png'
import MAIL_GREY from '../../assets/images/logo/mail_64_grey.png'

const PlatformSelector = ({ currPlatform, selectPlatform }) => {
  const [hovering, setHover] = React.useState('')
  // const isFacebookSelect = ['FacebookBot'].includes(currPlatform)
  // const isInstagramSelect = ['Instagram'].includes(currPlatform)
  const pickLogo = platform => currPlatform === platform || hovering === platform
  return (
    <div style={{ color: '#676f84' }}>
      <PlatformItem
        platform="All"
        currPlatform={currPlatform}
        onItemClick={selectPlatform}
        color="#206EB0"
        hovering={hovering}
        setHover={setHover}
      >
        <img
          src={pickLogo('All') ? MAIL_BLUE : MAIL_GREY}
          alt="mail-logo"
          style={{ width: 16, height: 16, marginRight: 8 }}
        />
        <span>ทั้งหมด</span>
      </PlatformItem>
      <PlatformItem
        platform="LineBot"
        currPlatform={currPlatform}
        onItemClick={selectPlatform}
        color="#32B159"
        hovering={hovering}
        setHover={setHover}
      >
        <img
          src={pickLogo('LineBot') ? LINE_GREEN : LINE_GREY}
          alt="line-logo"
          style={{ width: 16, height: 16, marginRight: 8 }}
        />
        <span>LINE</span>
      </PlatformItem>
      {/* <div
        style={{
          padding: 16,
          backgroundColor: 'rgba(219, 219, 219, 0.5)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <img
          src={isFacebookSelect ? FB_BLUE : FB_GREY}
          alt="fb-logo"
          style={{ width: 16, height: 16, marginRight: 8 }}
        />
        <span>Facebook</span>
      </div> */}
      <PlatformItem
        platform="FacebookBot"
        currPlatform={currPlatform}
        onItemClick={selectPlatform}
        color="#206EB0"
        // style={{ paddingLeft: 32 }}
        hovering={hovering}
        setHover={setHover}
      >
        <img
          src={pickLogo('FacebookBot') ? MG_BLUE : MG_GREY}
          alt="messenger-logo"
          style={{ width: 16, height: 16, marginRight: 8 }}
        />
        <span>Messenger</span>
      </PlatformItem>
      {/* <div
        style={{
          padding: 16,
          backgroundColor: 'rgba(219, 219, 219, 0.5)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <img
          src={isInstagramSelect ? IG_PURPLE : IG_GREY}
          alt="ig-logo"
          style={{ width: 16, height: 16, marginRight: 8 }}
        />
        <span>Instagram</span>
      </div> */}
    </div>
  )
}

PlatformSelector.propTypes = {
  currPlatform: PT.oneOf(['All', 'LineBot', 'FacebookBot']),
  selectPlatform: PT.func,
}

PlatformSelector.defaultProps = {
  selectPlatform: () => {},
}

export default PlatformSelector
