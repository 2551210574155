import React from 'react'
import PT from 'prop-types'
import { Spin } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { withRouter } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'

import ChatTopBar from './ChatTopBar'
import { FacebookComposerBar, LineComposerBar } from './ComposerBar'
import DateSnack from './DateSnack'
import { FacebookEventRender, LineEventRender } from './EventRender'
import customPT from '../../constants/proptypes'
import { Flex } from '../../styled'

const ALL_EVENTS = gql`
  query allEvents($chatId: ID!, $botId: ID!, $page: PaginationInput) {
    allEvents(chat_id: $chatId, bot_id: $botId, page: $page) {
      pagination {
        size
        next
      }
      events {
        chat_id
        type
        source {
          id
          type
        }
        message
        postback
        timestamp
      }
    }
  }
`

const SUBSCRIBE_EVENT = gql`
  subscription onInboxChanged($chatId: ID!, $botId: ID!) {
    onInboxChanged(chat_id: $chatId, bot_id: $botId) {
      chat_id
      type
      source {
        id
        type
      }
      message
      timestamp
    }
  }
`

const SEND_MESSAGE = gql`
  mutation sendMessage($botId: String!, $chatId: String!, $messages: JSON, $admin: String!) {
    sendMessage(bot_id: $botId, chat_id: $chatId, messages: $messages, admin: $admin)
  }
`

const UPLOAD_FILE = gql`
  mutation fileUpload($file: Upload!, $botId: String) {
    fileUpload(file: $file, bot_id: $botId) {
      id
      filename
      mimetype
      encoding
      url
    }
  }
`

const SET_BOT_ENABLED = gql`
  mutation setBotEnabled($botId: String!, $chatId: String!, $enabled: Boolean) {
    setBotEnabled(bot_id: $botId, chat_id: $chatId, enabled: $enabled)
  }
`

class ChatArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      moreLoading: false,
      prevScrollHeight: 0,
      showMessage: false,
      sendLoading: false,
      stickerState: false,
      scrollState: 'viewport',
    }
    this.scrollbar = React.createRef()
    this.msgLength = (props.chatEvents || []).length
    this.prevScrollHeight = 0
    this.unSubscribe = undefined
  }

  setStickerPanel = state => {
    this.setState({ stickerState: state }, () => {
      this.scrollbar.current.scrollToBottom()
    })
  }

  showNewMessageNoti = () => {
    const { scrollState } = this.state
    if (scrollState === 'bottom' || scrollState === 'viewport') {
      this.scrollbar.current.scrollToBottom()
    } else {
      this.setState({ showMessage: true })
    }
  }

  handleNotiClick = () => {
    this.scrollbar.current.scrollToBottom()
    this.setState({ showMessage: false })
  }

  handleScroll = values => {
    const { loadmoreEvents } = this.props
    if (loadmoreEvents && values.top === 0) {
      this.setState({
        moreLoading: true,
        scrollState: 'top',
      })

      loadmoreEvents(() => {
        this.setState({ moreLoading: false })
      })
    } else if (values.top === 1) {
      this.setState({ showMessage: false, scrollState: 'bottom' })
    } else {
      this.setState({ scrollState: 'scrolling' })
    }
  }

  handleScrollbarChange = values => {
    const { chatEvents } = this.props
    const { scrollState } = this.state
    if (this.msgLength === chatEvents.length) {
      if (this.prevScrollHeight !== values.scrollHeight) {
        // change when load image done
        if (!(scrollState === 'top' || scrollState === 'scrolling')) {
          // scroll to bottom if not scrolling
          this.scrollbar.current.scrollToBottom()
        }
      }
    } else {
      // change because message coming
      this.msgLength = chatEvents.length
    }
    this.prevScrollHeight = values.scrollHeight
  }

  componentDidMount() {
    this.unSubscribe = this.props.subscribeEvent()
    this.scrollbar.current && this.scrollbar.current.scrollToBottom()
  }

  componentDidUpdate(prevProps) {
    const { scrollState } = this.state
    const scrollValues = this.scrollbar.current.getValues()
    if (this.props.chatEvents[0] !== prevProps.chatEvents[0]) {
      // fixed scrollbar to prev first message when loadmore (first message change)
      const tmp = scrollValues.scrollHeight
      this.scrollbar.current.scrollTop(tmp - this.prevScrollHeight)
    }
    if (prevProps.chatId === this.props.chatId && this.props.lastUpdate !== prevProps.lastUpdate) {
      // new last message coming
      this.showNewMessageNoti()
    }
    if (this.props.chatEvents !== prevProps.chatEvents) {
      if (scrollValues.clientHeight !== scrollValues.scrollHeight && scrollState === 'viewport') {
        this.setState({ scrollState: 'top' })
      }
    }
    if (this.props.chatId !== prevProps.chatId) {
      this.unSubscribe()
      this.unSubscribe = this.props.subscribeEvent()
    }
  }

  componentWillUnmount() {
    this.unSubscribe()
  }

  render() {
    const {
      chatEvents,
      friendData,
      toggleChatInfo,
      mutateUpdate,
      sendMessage,
      fileUpload,
      setBotEnabled,
      botId,
      onBack,
    } = this.props
    const { moreLoading, showMessage, stickerState } = this.state
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <ChatTopBar
          botId={botId}
          friendData={friendData}
          toggleChatInfo={toggleChatInfo}
          setBotEnabled={setBotEnabled}
          mutateUpdate={mutateUpdate}
          onBack={onBack}
        />
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            height: 'calc(100% - 52px)',
          }}
        >
          <div
            style={{
              transition: 'all 0.35s',
              padding: '8px 16px',
              position: 'absolute',
              top: showMessage ? 0 : -37,
              left: 0,
              right: 0,
              backgroundColor: 'white',
              cursor: 'pointer',
              boxShadow: '2px 0 5px 0 rgba(0, 0, 0, 0.15)',
              zIndex: 110,
            }}
            onClick={this.handleNotiClick}
          >
            <span>Scroll down to see your new message.</span>
          </div>
          {moreLoading && (
            <Flex
              style={{
                justifyContent: 'center',
                marginTop: `calc(8px + ${showMessage ? 37 : 0}px)`,
                marginBottom: 8,
              }}
            >
              <Spin />
            </Flex>
          )}
          <Scrollbars
            ref={this.scrollbar}
            onUpdate={this.handleScrollbarChange}
            onScrollFrame={this.handleScroll}
            style={{
              marginTop: showMessage && !moreLoading ? '37px' : 0,
              height: `calc(100% - 49px - ${showMessage ? 37 : 0}px - ${moreLoading ? 41 : 0}px - ${
                stickerState ? 320 : 0
              }px)`,
              width: '100%',
              position: 'relative',
            }}
            autoHide
          >
            <div style={{ padding: '16px 8px' }}>
              {(chatEvents || []).map((e, i) => {
                const prevEvent = i === 0 ? null : chatEvents[i - 1]
                const showTime =
                  prevEvent &&
                  moment(Number(e.timestamp)).format('DDD') !==
                    moment(Number(prevEvent.timestamp)).format('DDD')
                return (
                  <div key={e.timestamp} style={{ marginRight: 8, marginBottom: 8 }}>
                    {prevEvent ? (
                      showTime && <DateSnack timestamp={e.timestamp} />
                    ) : (
                      <DateSnack timestamp={e.timestamp} />
                    )}
                    {friendData.platform === 'FacebookBot' && (
                      <FacebookEventRender event={e} friendData={friendData} />
                    )}
                    {friendData.platform === 'LineBot' && (
                      <LineEventRender event={e} friendData={friendData} botId={botId} />
                    )}
                  </div>
                )
              })}
            </div>
          </Scrollbars>
          {friendData.platform === 'FacebookBot' && (
            <FacebookComposerBar
              botId={botId}
              chatId={friendData.id}
              mutateSendMessage={sendMessage}
              mutateFileUpload={fileUpload}
            />
          )}
          {friendData.platform === 'LineBot' && (
            <LineComposerBar
              botId={botId}
              chatId={friendData.id}
              mutateSendMessage={sendMessage}
              mutateFileUpload={fileUpload}
              setStickerState={this.setStickerPanel}
              stickerState={stickerState}
            />
          )}
        </div>
      </div>
    )
  }
}

ChatArea.propTypes = {
  botId: PT.string,
  toggleChatInfo: PT.func,
  friendData: customPT.CHAT_PT,
  mutateUpdate: PT.func,
  onBack: PT.func,
}

export default withRouter(props => {
  const botId = props.match.params.botId
  const chatId = props.match.params.chatId

  const { data, error, subscribeToMore, fetchMore } = useQuery(ALL_EVENTS, {
    variables: { chatId, botId },
    fetchPolicy: 'cache-and-network',
  })
  const [sendMessage] = useMutation(SEND_MESSAGE)
  const [fileUpload] = useMutation(UPLOAD_FILE)
  const [setBotEnabled] = useMutation(SET_BOT_ENABLED)
  if (error) {
    console.error(error)
  }

  const allEvents = (data && data.allEvents) || []
  const events = allEvents.events || []
  const pagination = allEvents.pagination || {}

  const loadmoreEvents = cb => {
    fetchMore({
      variables: { chatId, botId, page: { size: pagination.size, start: pagination.next } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev
        }
        const newAllEventsData = fetchMoreResult.allEvents || {}
        cb && cb()
        return {
          ...prev,
          allEvents: {
            ...prev.allEvents,
            pagination: newAllEventsData.pagination,
            events: [...prev.allEvents.events, ...newAllEventsData.events],
          },
        }
      },
    })
  }

  const subscribeEvent = () =>
    subscribeToMore({
      document: SUBSCRIBE_EVENT,
      variables: { chatId, botId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const incomingEvent = subscriptionData.data.onInboxChanged || {}
        return {
          ...prev,
          allEvents: {
            ...prev.allEvents,
            events: [incomingEvent, ...prev.allEvents.events],
          },
        }
      },
    })

  const sortedEvents = events.length !== 0 ? events.sort((a, b) => a.timestamp - b.timestamp) : []
  return (
    <ChatArea
      {...props}
      botId={botId}
      chatId={chatId}
      chatEvents={sortedEvents}
      subscribeEvent={subscribeEvent}
      sendMessage={sendMessage}
      fileUpload={fileUpload}
      setBotEnabled={setBotEnabled}
      lastUpdate={sortedEvents.length !== 0 ? sortedEvents[sortedEvents.length - 1].timestamp : 0}
      loadmoreEvents={!!pagination.next && loadmoreEvents}
    />
  )
})
