import React from 'react'
import PT from 'prop-types'
import { Select } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TagEditor from './TagEditor'
import ImageRenderer from '../ImageRenderer'
import customProps from '../../constants/proptypes'
import WisibleButton from './WisibleButton'

const NO_USER_IMG =
  'https://blogg.hioa.no/rescue/wp-content/themes/hioa-blogg-2/assets/images/user.png'

const ProfileInfo = ({
  friendData,
  openTagModal,
  updateFriend,
  loading,
  onWisibleClick,
  wisibleEnabled,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        padding: '0px 16px 8px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
      }}
    >
      <div style={{ textAlign: 'center', marginBottom: 8 }}>
        <div
          style={{
            position: 'relative',
            margin: '0 auto 16px',
          }}
        >
          <ImageRenderer
            style={{
              width: 192,
              height: 192,
              border: '1px solid rgba(0, 0, 0, 0.15)',
              borderRadius: '50%',
            }}
            src={friendData.picture_url}
            alt={friendData.full_name}
            errorImg={NO_USER_IMG}
          />
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {wisibleEnabled && (
              <WisibleButton
                enabled={friendData.wisible && friendData.wisible.enabled}
                onClick={onWisibleClick}
              />
            )}
          </div>
        </div>
        <div style={{ marginBottom: 8, marginRight: 4, fontSize: '1.5em', fontWeight: 500 }}>
          {friendData.full_name}
        </div>
        <TagEditor tags={friendData.tags} openModal={openTagModal} />
        <div style={{ marginTop: 8 }}>
          <label style={{ marginRight: 8 }}>สถานะ</label>
          <Select
            style={{ width: 160 }}
            value={friendData.status}
            onChange={val => updateFriend({ status: val })}
            loading={loading}
          >
            <Select.Option key="DEFAULT">
              <FontAwesomeIcon icon="comments" style={{ marginRight: 8 }} />
              <span>ทั่วไป</span>
            </Select.Option>
            <Select.Option key="FOLLOWED_UP">
              <FontAwesomeIcon icon="comment-dots" style={{ marginRight: 8 }} />
              <span>ต้องดำเนินการ</span>
            </Select.Option>
            <Select.Option key="DONE">
              <FontAwesomeIcon icon="comment-dollar" style={{ marginRight: 8 }} />
              <span>ดำเนินการแล้ว</span>
            </Select.Option>
            <Select.Option key="SPAM">
              <FontAwesomeIcon icon="comment-slash" style={{ marginRight: 8 }} />
              <span>สแปม</span>
            </Select.Option>
          </Select>
        </div>
      </div>
    </div>
  )
}

ProfileInfo.propTypes = {
  friendData: customProps.CHAT_PT,
  openTagModal: PT.func,
  onWisibleClick: PT.func,
  updateFriend: PT.func,
  loading: PT.bool,
  wisibleEnabled: PT.bool,
}

ProfileInfo.defaultProps = {
  friendData: {},
  openTagModal: () => {},
  onWisibleClick: () => {},
  updateFriend: () => {},
}

export default ProfileInfo
