import React from 'react'
import PT from 'prop-types'
import { Button, Icon } from 'antd'

import AIYA_BOT_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../../components/ImageRenderer'
import Fb from '../../../service/_facebookClient'

const Completed = ({ data, gotoRedirectClick }) => {
  const [pageImage, setPageImage] = React.useState()

  React.useEffect(() => {
    if (window.FB) {
      Fb.getPagePicture(data.id, res => {
        setPageImage((res.data || {}).url)
      })
    }
  }, [])

  return (
    <div style={{ height: 'calc(100% - 60px)', padding: '32px 16px 16px' }}>
      <h2 style={{ color: '#32B159', textAlign: 'center', marginBottom: 24 }}>
        เชื่อมต่อเสร็จสมบูรณ์
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 24,
        }}
      >
        <div style={{ position: 'relative' }}>
          <ImageRenderer
            src={pageImage}
            alt={data.id || '-'}
            errorImg={AIYA_BOT_BLANK}
            style={{ width: 96, height: 96, borderRadius: '50%', marginBottom: 16 }}
          />
          <Icon
            type="check-circle"
            theme="filled"
            style={{ position: 'absolute', top: 4, right: 4, color: '#32B159', fontSize: '18px' }}
          />
        </div>
        <h3 style={{ marginBottom: 0 }}>{data.name || '-'}</h3>
        <small>ID: {data.id || '-'}</small>
      </div>
      <Button type="primary" size="large" block onClick={gotoRedirectClick}>
        ไปยังหน้าหลัก 1-on-1 แชท
      </Button>
    </div>
  )
}

Completed.propTypes = {
  data: PT.shape({
    id: PT.string,
    name: PT.string,
    access_token: PT.string,
  }),
  gotoRedirectClick: PT.func,
}

Completed.defaultProps = {
  data: {},
  gotoRedirectClick: () => {},
}

export default Completed
