import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Icon } from 'antd'
import { motion } from 'framer-motion'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import ACRM_LOGO from '../../assets/images/ACRM_logo.png'
import ChatSidebar from '../../components/ChatSidebar'
import Loading from '../../components/Loading'
import BotSelector from './BotSelector'
import ChatContainer from './ChatContainer'

const ALL_CHANNEL = gql`
  query allChannels($botId: String!) {
    allChannels(bot_id: $botId) {
      platform
      title
      id
    }
  }
`

const MobileLayout = ({ match }) => {
  const botId = match.params.botId
  const [drawerState, setDrawer] = React.useState(false)

  const handleChannelSelect = ch => {
    setDrawer(false)
  }

  const { data, error, loading } = useQuery(ALL_CHANNEL, {
    variables: { botId },
    fetchPolicy: 'cache-and-network',
  })
  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }
  const allChannels = (data && data.allChannels) || []

  const chatChannels = allChannels.filter(ch => ['FacebookBot', 'LineBot'].includes(ch.platform))
  // if (chatChannels.length === 0) {
  //   return <Redirect to={`/${botId}/connect-facebook`} />
  // }
  console.log(chatChannels)
  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          height: '100%',
          maxHeight: drawerState ? '100%' : '60px',
          transition: 'all 0.35s',
          boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 60,
            padding: '6px 12px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <img src={ACRM_LOGO} alt="ACRM" style={{ height: 48 }} />
          {/* <motion.div
            onClick={() => setDrawer(!drawerState)}
            whileTap={{ rotate: drawerState ? 90 : -90 }}
          >
            <Icon
              type={drawerState ? 'close' : 'menu'}
              style={{ fontSize: 24, color: '#2075C8', cursor: 'pointer' }}
            />
          </motion.div> */}
        </div>
        <BotSelector channels={chatChannels} onSelect={handleChannelSelect} />
      </div>

      <div style={{ height: 'calc(100%)', position: 'relative', paddingTop: 60 }}>
        <Switch>
          <Redirect from="/:botId/chat" to="/:botId/chat/All/list" exact />
          <Route
            path="/:botId/chat/:platform/list"
            render={props => <ChatSidebar platform={props.match.params.platform} useHistory />}
          />
          <Route
            path="/:botId/chat/:platform/:chatId"
            render={props => <ChatContainer {...props} />}
          />
        </Switch>
      </div>
    </div>
  )
}

export default MobileLayout
