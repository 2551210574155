import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

import ImageRenderer from '../ImageRenderer'

const LOGO = 'https://www.wisible.io/wp-content/uploads/2019/08/Wisible_monogram.jpg'

const Container = styled.div`
  padding: 4px 12px;
  border-radius: 24px;
  border: 1px solid ${({ enabled }) => (enabled ? '#12d7be' : '#b7b7b7')};
  background-color: ${({ enabled }) => (enabled ? 'white' : '#dbdbdb')};
  cursor: pointer;
  &:hover {
    background-color: #e4e4e4;
  }
`

const WisibleButton = ({ enabled, onClick }) => {
  return (
    <Container enabled={enabled} onClick={onClick}>
      <ImageRenderer
        src={LOGO}
        alt="wisible-logo"
        width={32}
        height={32}
        style={{ borderRadius: '50%', marginRight: 8, pointerEvents: 'none' }}
      />
      <span style={{ color: enabled ? '#137890' : 'white' }}>Wisible</span>
    </Container>
  )
}

WisibleButton.propTypes = {
  enabled: PT.bool,
  onClick: PT.func,
}

WisibleButton.defaultProps = {
  onClick: () => {},
}

export default WisibleButton
