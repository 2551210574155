import React from 'react'
import PT from 'prop-types'
import { Avatar, Icon, Switch } from 'antd'

import customPT from '../../constants/proptypes'
import { Flex, IconBtn } from '../../styled'

const ChatTopBar = ({ botId, friendData, toggleChatInfo, setBotEnabled, onBack }) => {
  const [loading, setLoading] = React.useState(false)

  const onToggle = async enabled => {
    try {
      setLoading(true)
      await setBotEnabled({ variables: { botId, chatId: friendData.id, enabled } })
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  return (
    <Flex
      style={{
        height: 52,
        padding: '0 8px',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          width: 'calc(100% - 90px - 8px)',
        }}
      >
        {onBack && (
          <IconBtn onClick={() => onBack()} style={{ marginRight: 8 }} circle>
            <Icon type="arrow-left" />
          </IconBtn>
        )}
        <Avatar size="large" style={{ marginRight: 8 }} src={friendData.picture_url}>
          {(friendData.full_name || '').slice(0, 1).toUpperCase()}
        </Avatar>
        <div
          style={{
            fontSize: '1.4em',
            fontWeight: 500,
            width: 'calc(100% - 38px - 48px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {friendData.full_name || 'Full Name'}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Switch
            checked={friendData.bot_enabled}
            onChange={onToggle}
            loading={loading}
            style={{ marginRight: 8 }}
            checkedChildren="On"
            unCheckedChildren="Off"
          />
        </div>
        <IconBtn onClick={() => toggleChatInfo()} circle>
          <Icon type="info-circle" />
        </IconBtn>
      </div>
    </Flex>
  )
}

ChatTopBar.propTypes = {
  friendData: customPT.CHAT_PT,
  toggleChatInfo: PT.func,
  setBotEnabled: PT.func,
  onBack: PT.func,
}

ChatTopBar.defaultProps = {
  toggleChatInfo: () => {},
  setBotEnabled: () => {},
}

export default ChatTopBar
