import PT from 'prop-types'

const PROFILE_PT = PT.shape({
  user_id: PT.string,
  display_name: PT.string,
  image_url: PT.string,
  blocked: PT.bool,
  tags: PT.arrayOf(PT.string),
})

const CHAT_EVENT_PT = PT.shape({
  chat_id: PT.string,
  type: PT.oneOf(['MESSAGE', 'MESSAGE_SENT', 'FOLLOW', 'UNFOLLOW']),
  source: PT.shape({
    type: PT.oneOf(['USER', 'ADMIN', 'BOT', 'BROADCAST']),
    id: PT.string,
  }),
  message: PT.object,
  postback: PT.object,
  timestamp: PT.number,
  read: PT.bool,
  bot_enabled: PT.bool,
})

const CHAT_PT = PT.shape({
  id: PT.string,
  channel_id: PT.string,
  social_id: PT.string,
  full_name: PT.string,
  picture_url: PT.string,
  platform: PT.oneOf(['LineBot', 'FacebookBot']),
  tags: PT.arrayOf(PT.string),
  bc_data: PT.object,
  blocked: PT.bool,
  creation: PT.number,
  last_event: CHAT_EVENT_PT,
  status: PT.oneOf(['DEFAULT', 'FOLLOWED_UP', 'DONE', 'SPAM']),
})

export default { PROFILE_PT, CHAT_EVENT_PT, CHAT_PT }
