import React from 'react'
import PT from 'prop-types'
import { Icon, Tag } from 'antd'

import { IconBtn } from '../../styled'

const TagEditor = ({ tags, openModal }) => {
  if ((tags || []).length) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {tags.map(t => (
          <Tag key={t} style={{ marginRight: 4, marginBottom: 4 }}>
            {t}
          </Tag>
        ))}
        <IconBtn circle style={{ width: 24, height: 24 }}>
          <Icon type="edit" onClick={openModal} />
        </IconBtn>
      </div>
    )
  } else {
    return (
      <div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={openModal}>
        <Icon type="tag" style={{ marginRight: 4 }} />
        <span>เพิ่มแท็ก</span>
      </div>
    )
  }
}

TagEditor.propTypes = {
  tags: PT.arrayOf(PT.string),
  openModal: PT.func,
}

TagEditor.defaultProps = {
  tags: [],
  openModal: () => {},
}

export default TagEditor
