import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Drawer } from 'antd'

import ChatArea from '../../components/ChatArea'
import ChatInfo from '../../components/ChatInfo'
import Loading from '../../components/Loading'

const GET_CHAT = gql`
  query getChat($chatId: ID!, $botId: ID!) {
    getChat(chat_id: $chatId, bot_id: $botId) {
      id
      channel_id
      social_id
      full_name
      picture_url
      platform
      tags
      bc_data
      blocked
      creation
      status
      bot_enabled
      wisible {
        id
        url
        enabled
      }
    }
  }
`

const UPDATE_CHAT = gql`
  mutation updateChat($botId: String!, $chatId: ID!, $input: ChatUpdate!) {
    updateChat(input: $input, chat_id: $chatId, bot_id: $botId)
  }
`

const ChatScreen = ({ match }) => {
  const botId = match.params.botId
  const chatId = match.params.chatId
  const [showInfo, setInfoState] = React.useState(false)

  const { data, error, loading } = useQuery(GET_CHAT, {
    variables: { chatId, botId },
    fetchPolicy: 'cache-and-network',
  })
  const [updateChat] = useMutation(UPDATE_CHAT, {
    refetchQueries: [{ query: GET_CHAT, variables: { chatId, botId } }],
  })

  if (loading) return <Loading />
  if (error) {
    console.error(error.message)
  }

  const getChat = (data && data.getChat) || {}

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ChatArea
          toggleChatInfo={() => setInfoState(!showInfo)}
          friendData={getChat}
          mutateUpdate={updateChat}
          botId={botId}
        />
      </div>
      <Drawer
        width={320}
        title={`ข้อมูลของ ${getChat.full_name}`}
        placement="right"
        onClose={() => setInfoState(false)}
        visible={showInfo}
        closable={false}
        bodyStyle={{ padding: 0 }}
      >
        <ChatInfo botId={botId} friendData={getChat} mutateUpdate={updateChat} />
      </Drawer>
    </>
  )
}

export default ChatScreen
