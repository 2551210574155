import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Loading from '../components/Loading'
import BotContext from '../context/Bot'
import DesktopLayout from './Desktop'
import MobileLayout from './Mobile'
import TabletLayout from './Tablet'

const GET_BOT = gql`
  query getBotInfo($botId: String!) {
    getBotInfo(bot_id: $botId) {
      name
      title
      image
      wisible_enabled
    }
  }
`

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

const AppLayouts = props => {
  const botId = props.match.params.botId
  const { data: botData, loading: botLoading, error: botError } = useQuery(GET_BOT, {
    variables: { botId },
  })
  if (botError) {
    console.error(botError)
  }
  if (botLoading) {
    return <Loading />
  }
  const getBot = (botData && botData.getBotInfo) || {}

  return (
    <BotContext.Provider value={getBot}>
      <Mobile>
        <MobileLayout {...props} />
      </Mobile>

      <Tablet>
        <TabletLayout {...props} />
      </Tablet>

      <Desktop>
        <DesktopLayout {...props} />
      </Desktop>
    </BotContext.Provider>
  )
}

export default AppLayouts
