import React from 'react'
import PT from 'prop-types'
import PageItem from './PageItem'
import { Button } from 'antd'

const PageList = ({ loading, pages, onPageSelect }) => {
  const [selected, selectPage] = React.useState({})
  return (
    <div style={{ height: 'calc(100% - 60px)' }}>
      <div style={{ height: 'calc(100% - 81px)', padding: '32px 8px 16px', overflow: 'auto' }}>
        <h2 style={{ color: '#A00326', textAlign: 'center', marginBottom: 16 }}>เลือกเพจ</h2>
        {pages.length ? (
          pages.map(p => (
            <PageItem
              key={p.id}
              data={p}
              selected={selected.id === p.id}
              onSelect={() => selectPage(p)}
            />
          ))
        ) : (
          <div
            style={{
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <i>
              - ไม่พบเพจที่คุณเป็นแอดมิน -
              <br />
              โปรดตรวจสอบสิทธิ์การเข้าถึงในบัญชี Facebook ของท่าน
            </i>
          </div>
        )}
      </div>
      <div style={{ padding: 16, borderTop: '1px solid rgba(0, 0, 0, 0.15)' }}>
        <Button
          type="primary"
          size="large"
          style={{ marginBottom: 8 }}
          onClick={() => onPageSelect(selected)}
          loading={loading}
          disabled={!selected.id}
          block
        >
          เชื่อมต่อไปยัง Facebook Page
        </Button>
      </div>
    </div>
  )
}

PageList.propTypes = {
  pages: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      access_token: PT.string,
    }),
  ),
  onPageSelect: PT.func,
  loading: PT.bool,
}

PageList.defaultProps = {
  pages: [],
  onPageSelect: () => {},
}

export default PageList
