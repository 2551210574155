import ApolloClient from 'apollo-client'
import { split } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-boost'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'

import fetch from 'node-fetch'

// const baseUrl = 'https://chat.aiya.ai/api'
// const socketUrl = 'wss://chat.aiya.ai/api/graphql'

// const baseUrl = 'http://localhost:22222'
// const socketUrl = 'ws://localhost:22222/graphql'

const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
let baseUrl = 'http://localhost:22222'
let socketUrl = 'ws://localhost:22222/graphql'

let { host } = window.location
if (process.env.NODE_ENV !== 'development') {
  // baseUrl = `${window.location.protocol}//${host}/api`
  // socketUrl = `${protocolPrefix}//${host}/api/graphql`
  baseUrl = `${window.location.protocol}//chat-api.aiya.ai/graphql`
  socketUrl = `${protocolPrefix}//chat-api.aiya.ai/graphql`
}

// baseUrl = 'https://377f85fc.ngrok.io'
// socketUrl = 'wss://377f85fc.ngrok.io/graphql'
// baseUrl = `https://chat-api.aiya.ai/api`
// socketUrl = `https://chat-api.aiya.ai/api/graphql`

console.log({ baseUrl, NODE_ENV: process.env.NODE_ENV, socketUrl })
const uploadLink = createUploadLink({ uri: baseUrl })
const wsLink = new WebSocketLink({
  fetch,
  uri: socketUrl,
  options: {
    reconnect: true,
  },
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access_token')
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink,
)

const client = new ApolloClient({
  link: link
    .concat(
      onError(({ response = {} }) => {
        console.error('Global Error', response)
        const err = (response.errors || [])[0] || {}
        if ((err.message || '').substr(0, 14) === 'Not Authorized') {
          window.location.href = process.env.PUBLIC_URL
        }
      }),
    )
    .concat(uploadLink),
  cache: new InMemoryCache({
    dataIdFromObject: (obj) => {
      if (obj.__typename === 'Channel') return obj.name
      return defaultDataIdFromObject(obj)
    },
  }),
})

export { client, baseUrl }
