import React from 'react'
import { Icon } from 'antd'

const Loading = () => {
  return (
    <div
      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <Icon type="loading" style={{ marginRight: 8 }} />
      <span>Loading..</span>
    </div>
  )
}

export default Loading
