import React from 'react'
import { Divider, Avatar } from 'antd'
import moment from 'moment'

import { MessageRender } from '@megenius/aiya-chat-ui'

import customPT from '../../../constants/proptypes'
import { Snack, Flex } from '../../../styled'

const FacebookEventRender = ({ event, friendData }) => {
  const eventMsg = event.message || {}
  const eventSource = event.source || {}
  let msg = {
    id: eventMsg.id,
    type: 'box',
    message: { ...eventMsg, mid: undefined },
  }

  if (eventSource.type === 'USER') {
    if (eventMsg.attachments) {
      const type = eventMsg.attachments[0].type
      const payload = eventMsg.attachments[0].payload || {}
      if (type === 'image') {
        msg = {
          ...msg,
          message: {
            attachment: {
              type: 'image',
              payload,
            },
          },
        }
      }
    }
    return (
      <Flex style={{ alignItems: 'flex-end', width: '100%' }}>
        <Avatar style={{ marginRight: 8 }} src={friendData.picture_url}>
          {(friendData.full_name || '').slice(0, 1).toUpperCase()}
        </Avatar>
        <div style={{ maxWidth: 'calc(100% - 90px)' }}>
          <MessageRender channel="facebook" data={msg} align="left" />
        </div>
        <small style={{ color: '#aaa', marginLeft: 4 }}>
          {moment(Number(event.timestamp)).format('HH.mm น.')}
        </small>
      </Flex>
    )
  } else if (eventSource.type === 'BOT' || eventSource.type === 'ADMIN') {
    return (
      <Flex style={{ justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
        <div
          style={{
            marginRight: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            color: '#aaa',
          }}
        >
          <small>{moment(Number(event.timestamp)).format('HH.mm น.')}</small>
          {eventSource.type === 'ADMIN' && <small>{eventSource.id}</small>}
        </div>
        <div style={{ maxWidth: 'calc(100% - 48px)' }}>
          <MessageRender channel="facebook" data={msg} align="right" />
        </div>
      </Flex>
    )
  } else if (event.type === 'FOLLOW') {
    return (
      <Flex style={{ padding: '0 16px' }}>
        <Divider style={{ lineHeight: 1 }}>
          <Snack>
            <small>User enter chat room</small>
          </Snack>
        </Divider>
      </Flex>
    )
  } else if (event.type === 'UNFOLLOW') {
    return (
      <Flex style={{ padding: '0 16px' }}>
        <Divider style={{ lineHeight: 1 }}>
          <Snack>
            <small>User leave chat room</small>
          </Snack>
        </Divider>
      </Flex>
    )
  } else {
    return null
  }
}

FacebookEventRender.propTypes = {
  event: customPT.CHAT_EVENT_PT,
  friendData: customPT.CHAT_PT,
}

export default FacebookEventRender
