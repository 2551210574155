import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { Avatar, Badge, Tag } from 'antd'
import moment from 'moment'

import customPT from '../../constants/proptypes'
import { Flex, WrappedText } from '../../styled'

const LOGO = 'https://www.wisible.io/wp-content/uploads/2019/08/Wisible_monogram.jpg'

const Box = styled.div`
  padding: 16px;
  background-color: white;
  cursor: pointer;
  &:hover {
    background-color: #e1ebff;
  }
  &.selected {
    background-color: #464d69 !important;
  }
`

const PrimaryText = styled(WrappedText)`
  font-size: 14px;
  &.selected {
    color: white !important;
  }
`

const SecondaryText = styled(WrappedText)`
  font-size: 12px;
  font-weight: 300;
  &.selected {
    color: white !important;
  }
`

const getChatDate = date => {
  const str = date.calendar(null, {
    sameDay: 'HH:mm',
    lastDay: '[Yesterday]',
    lastWeek: 'dddd',
    sameElse: 'DD MMM YYYY',
  })
  return str
}

const ChatItem = ({ data, isSelected, onItemClick }) => {
  const lastEvent = data.last_event || {}

  const eventOwner = (lastEvent.source || {}).type
  const eventMsg = lastEvent.message || {}
  const eventPostback = lastEvent.postback || {}

  const getStatusProperty = status => {
    switch (status) {
      case 'FOLLOWED_UP':
        return {
          text: 'ต้องดำเนินการ',
          color: 'gold',
        }
      case 'DONE':
        return {
          text: 'ดำเนินการแล้ว',
          color: 'green',
        }
      case 'SPAM':
        return {
          text: 'สแปม',
          color: 'red',
        }
      default:
        return null
    }
  }

  const getRenderTextMessage = () => {
    // USER
    if (data.platform === 'LineBot') {
      if (eventPostback.data) {
        return `[Data]: ${eventPostback.data}`
      } else if (eventMsg.type === 'text') {
        return eventMsg.text
      } else if (eventMsg.type === 'custom') {
        return `sent ${eventMsg.template}`
      } else {
        return `sent ${eventMsg.type}`
      }
    }

    if (data.platform === 'FacebookBot') {
      if (eventMsg.text) {
        return eventMsg.text
      }
    }
    // BOT or ADMIN
    // if (lastEvent.type === 'MESSAGE_SENT') {
    //   if (eventMsg.type === 'flex') {
    //     return eventMsg.altText
    //   } else if (eventMsg.type === 'template') {
    //     return eventMsg.altText
    //   } else if (eventMsg.type === 'imagemap') {
    //     return eventMsg.altText
    //   }
    // }
    return '-'
  }

  const statusTag = getStatusProperty(data.status)

  return (
    <Box className={isSelected && 'selected'} onClick={onItemClick}>
      <div style={{ float: 'left', marginRight: 8, position: 'relative' }}>
        <Badge dot count={lastEvent.read ? 0 : 1} offset={[-5, 5]}>
          <Avatar
            size="large"
            src={data.picture_url}
            style={{
              border: '2px solid white',
              borderColor: data.platform === 'LineBot' ? '#32B259' : '#1E7CFF',
            }}
          >
            {(data.full_name || '').slice(0, 1).toUpperCase()}
          </Avatar>
        </Badge>
        {(data.wisible || {}).enabled && (
          <img
            src={LOGO}
            alt="wisible-logo"
            style={{
              position: 'absolute',
              bottom: 0,
              right: -4,
              borderRadius: '50%',
              width: 16,
              height: 16,
            }}
          />
        )}
      </div>
      <Flex style={{ justifyContent: 'space-between' }}>
        <div style={{ width: 'calc(100% - 98px)' }} className="text-wrap-ellipsis">
          <PrimaryText
            className={isSelected && 'selected'}
            style={{ fontWeight: lastEvent.read ? 400 : 600 }}
          >
            {data.full_name || 'Full Name'}
          </PrimaryText>
        </div>
        <div>
          {statusTag && (
            <Tag color={statusTag.color} style={{ margin: '0 0 0 8px' }}>
              {statusTag.text}
            </Tag>
          )}
        </div>
      </Flex>
      <Flex style={{ justifyContent: 'space-between' }}>
        <SecondaryText
          className={isSelected && 'selected'}
          style={{
            color: 'rgba(0, 0, 0, 0.45)',
            maxWidth: 'calc(100% - 80px)',
            fontWeight: !lastEvent.read && 700,
          }}
        >
          {eventOwner !== 'USER' && <span style={{ marginRight: 4 }}>{eventOwner}:</span>}
          <span>{getRenderTextMessage()}</span>
        </SecondaryText>
        {lastEvent.timestamp && (
          <SecondaryText className={isSelected && 'selected'}>
            {getChatDate(moment(Number(lastEvent.timestamp)))}
          </SecondaryText>
        )}
      </Flex>
    </Box>
  )
}

ChatItem.propTypes = {
  data: customPT.CHAT_PT,
  isSelected: PT.bool,
  onItemClick: PT.func,
}

ChatItem.defaultProps = {
  onItemClick: data => {},
}

export default ChatItem
