import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'

import FB_BLUE from '../../assets/images/logo/facebook_64_blue.png'
import LINE_GREEN from '../../assets/images/logo/line_64_green.png'

const BotSelector = ({ channels, onSelect }) => {
  return (
    <div>
      {channels.map(ch => (
        <div
          key={ch.name}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px 12px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
          onClick={() => onSelect(ch)}
        >
          {ch.platform === 'FacebookBot' && (
            <img src={FB_BLUE} alt="fb-logo" style={{ marginRight: 12 }} width={36} height={36} />
          )}
          {ch.platform === 'LineBot' && (
            <img
              src={LINE_GREEN}
              alt="line-logo"
              style={{ marginRight: 12 }}
              width={36}
              height={36}
            />
          )}
          <div>
            <div style={{ fontSize: '1.1em', fontWeight: 500 }}>{ch.title}</div>
            <small style={{ color: '#B7B7B7' }}>ID: {ch.id}</small>
          </div>
          <Icon
            style={{ position: 'absolute', top: '50%', right: 12, transform: 'translateY(-50%)' }}
            type="right"
          />
        </div>
      ))}
    </div>
  )
}

BotSelector.propTypes = {
  channels: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
      id: PT.string,
      platform: PT.oneOf(['FacebookBot', 'LineBot']),
    }),
  ),
  onSelect: PT.func,
}

BotSelector.defaultProps = {
  channels: [],
  onSelect: () => {},
}

export default BotSelector
