import React from 'react'
import { Icon, Button } from 'antd'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import ACRM_LOGO from '../../../assets/images/ACRM_logo.png'
import FB_LOGO from '../../../assets/images/Logo_FB.png'
import Fb from '../../../service/_facebookClient'
import PageList from './PageList'
import PleaseConnect from './PleaseConnect'
import Completed from './Completed'

const CREATE_CHANNEL = gql`
  mutation createChannel($botId: String!, $platform: Platform!, $input: ChannelInput) {
    createChannel(bot_id: $botId, platform: $platform, input: $input) {
      name
    }
  }
`

class ConnectFacebook extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      step: 0,
      pages: [],
      selectedPage: undefined,
    }
  }

  handleConnectFacebookClick = () => {
    const email = localStorage.getItem('user_id')
    const onLoginStateChange = res => {
      if (res.status === 'connected') {
        Fb.getUserAccount(res => {
          this.setState({ pages: res.data, step: 1 })
        })
      } else if (res.status === 'not_authorized') {
        Fb.login(email, onLoginStateChange)
      } else if (res.status === 'unknown') {
        Fb.login(email, onLoginStateChange)
      }
    }

    Fb.login(email, onLoginStateChange)
  }

  handlePageSelect = async page => {
    const { botId, createChannel } = this.props
    const input = {
      enabled: true,
      title: page.name,
      id: page.id,
      access_token: page.access_token,
    }
    try {
      this.setState({ loading: true })
      await createChannel({ variables: { botId, platform: 'FacebookBot', input } })
      this.setState({ step: 2, loading: false, selectedPage: page })
    } catch (err) {
      console.error(err)
    }
  }

  handleRedirectClick = () => {
    const { history, botId } = this.props
    history.replace(`/${botId}/chat`)
  }

  componentDidMount() {
    Fb.initialFbSdk(() => {
      this.setState({ loading: false })
    })
  }

  render() {
    const { loading, step, pages, selectedPage } = this.state
    return (
      <div style={{ height: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 60,
            padding: '6px 12px',
            boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.1)',
          }}
        >
          <img src={ACRM_LOGO} alt="ACRM" style={{ height: 48 }} />
        </div>
        {step === 0 && <PleaseConnect onConnectClick={this.handleConnectFacebookClick} />}
        {step === 1 && (
          <PageList loading={loading} pages={pages} onPageSelect={this.handlePageSelect} />
        )}
        {step === 2 && (
          <Completed data={selectedPage} gotoRedirectClick={this.handleRedirectClick} />
        )}
      </div>
    )
  }
}

export default props => {
  const botId = props.match.params.botId
  const [createChannel] = useMutation(CREATE_CHANNEL)
  return <ConnectFacebook {...props} botId={botId} createChannel={createChannel} />
}
