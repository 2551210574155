import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const isAuthenticated = () => {
  const isAuth = !!localStorage.getItem('access_token')
  return isAuth
}

const PrivateRoute = ({ render, component: Component, path, exact }) => (
  <Route
    path={path}
    render={
      render ||
      (props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { curr: path } }} />
        ))
    }
    exact={exact}
  />
)

export default PrivateRoute
