import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

import { hexToRgbA } from '../../utils'

const Item = styled.div`
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.15s;
  ${({ selected, isHover, color }) =>
    (selected || isHover) &&
    `
      font-weight: 700;
      color: ${hexToRgbA(color)};
      background-color: ${hexToRgbA(color).replace(',1)', ',0.1)')};
  `}
  &:after {
    display: block;
    content: '';
    transition: all 0.35s;
    position: absolute;
    right: ${({ selected, isHover }) => (selected || isHover ? '0px' : '-6px')};
    top: 0px;
    bottom: -1px;
    width: 5px;
    background-color: ${({ color }) => hexToRgbA(color)};
  }
`

const PlatformItem = ({
  platform,
  hovering,
  setHover,
  currPlatform,
  onItemClick,
  color,
  style,
  children,
}) => {
  return (
    <Item
      selected={currPlatform === platform}
      isHover={hovering === platform}
      onMouseEnter={() => setHover(platform)}
      onMouseLeave={() => setHover('')}
      onClick={() => onItemClick(platform)}
      color={color}
      style={style}
    >
      {children}
    </Item>
  )
}

PlatformItem.propTypes = {
  hovering: PT.string,
  setHover: PT.func,
  platform: PT.string,
  currPlatform: PT.string,
  onItemClick: PT.func,
  color: PT.string,
  style: PT.object,
  children: PT.oneOfType([PT.node, PT.string]),
}

PlatformItem.defaultProps = {
  color: '#206EB0',
  setHover: () => {},
  onItemClick: () => {},
}

export default PlatformItem
