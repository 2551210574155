import React from 'react'
import PT from 'prop-types'
import { Avatar, Divider, Icon } from 'antd'
import moment from 'moment'

import { MessageRender } from '@megenius/aiya-chat-ui'

import customPT from '../../../constants/proptypes'
import { Flex, Snack } from '../../../styled'

const LineEventRender = ({ event, friendData, botId }) => {
  const eventMsg = event.message || {}
  const eventPostback = event.postback || {}
  const eventSource = event.source || {}
  let msg = {
    id: eventMsg.id,
    type: 'box',
    message: { ...eventMsg, id: undefined },
  }

  if (eventSource.type === 'USER') {
    if (eventPostback.data) {
      msg = {
        ...msg,
        message: {
          type: 'text',
          text: `[Data]: ${eventPostback.data}`,
        },
      }
    } else if (eventMsg.type === 'text') {
      msg = {
        ...msg,
        message: {
          type: 'text',
          text: eventMsg.text,
        },
      }
    } else if (eventMsg.type === 'image') {
      msg = {
        ...msg,
        message: {
          type: 'image',
          originalContentUrl: `https://cdn.aiya.ai/bot/${botId}/channel/${friendData.channel_id}/content/${eventMsg.id}`,
          previewImageUrl: `https://cdn.aiya.ai/bot/${botId}/channel/${friendData.channel_id}/content/${eventMsg.id}`,
        },
      }
      // } else if (eventMsg.type === 'video') {
      //   msg = {
      //     ...msg,
      //     message: {
      //       type: 'video',
      //       originalContentUrl: `https://cdn.aiya.ai/bot/${botId}/channel/${friendData.channel_id}/content/${eventMsg.id}`,
      //       previewImageUrl: `https://cdn.aiya.ai/bot/${botId}/channel/${friendData.channel_id}/content/${eventMsg.id}`,
      //     },
      //   }
      // } else if (eventMsg.type === 'location') {
      //   msg = {
      //     ...msg,
      //     message: {
      //       type: 'location',
      //       title: 'User location',
      //       address: eventMsg.address,
      //       latitude: eventMsg.latitude,
      //       longitude: eventMsg.longitude,
      //     },
      //   }
    } else if (eventMsg.type === 'sticker') {
      msg = {
        ...msg,
        message: {
          type: 'sticker',
          stickerUrl: `https://stickershop.line-scdn.net/stickershop/v1/sticker/${eventMsg.stickerId}/ANDROID/sticker.png`,
        },
      }
    } else {
      msg = {
        ...msg,
        message: {
          type: 'text',
          text: `sent a ${eventMsg.type}`,
        },
      }
    }
    return (
      <Flex style={{ alignItems: 'flex-end', width: '100%' }}>
        <Avatar style={{ marginRight: 8 }} src={friendData.picture_url}>
          {(friendData.full_name || '').slice(0, 1).toUpperCase()}
        </Avatar>
        <div style={{ maxWidth: 'calc(100% - 90px)' }}>
          <MessageRender channel="line" data={msg} align="left" />
        </div>
        <small style={{ color: '#aaa', marginLeft: 4 }}>
          {moment(Number(event.timestamp)).format('HH.mm น.')}
        </small>
      </Flex>
    )
  } else if (eventSource.type === 'BOT' || eventSource.type === 'ADMIN') {
    if (eventMsg.type === 'custom') {
      if (eventMsg.template === 'file') {
        const templateData = eventMsg.data
        return (
          <Flex style={{ justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
            <div
              style={{
                marginRight: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                color: '#aaa',
              }}
            >
              <small>{moment(Number(event.timestamp)).format('HH.mm น.')}</small>
              {eventSource.type === 'ADMIN' && <small>{eventSource.id}</small>}
            </div>
            <div style={{ maxWidth: 'calc(100% - 48px)' }}>
              <div
                style={{
                  backgroundColor: '#bef18c',
                  borderRadius: 18,
                  padding: '8px 16px',
                  color: '#314f22',
                }}
              >
                <Icon type="file" />
                <a
                  style={{ marginLeft: 8, textDecoration: 'underline', color: '#314f22' }}
                  href={templateData.url}
                >
                  {templateData.filename}
                </a>
              </div>
            </div>
          </Flex>
        )
      }
    }
    if (eventMsg.type === 'sticker') {
      msg = {
        ...msg,
        message: {
          type: 'sticker',
          stickerUrl: `https://stickershop.line-scdn.net/stickershop/v1/sticker/${eventMsg.stickerId}/ANDROID/sticker.png`,
        },
      }
    }
    return (
      <Flex style={{ justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
        <div
          style={{
            marginRight: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            color: '#aaa',
          }}
        >
          <small>{moment(Number(event.timestamp)).format('HH.mm น.')}</small>
          {eventSource.type === 'ADMIN' && <small>{eventSource.id}</small>}
        </div>
        <div style={{ maxWidth: 'calc(100% - 48px)' }}>
          <MessageRender channel="line" data={msg} align="right" />
        </div>
      </Flex>
    )
  } else if (event.type === 'FOLLOW') {
    return (
      <Flex style={{ padding: '0 16px' }}>
        <Divider style={{ lineHeight: 1 }}>
          <Snack>
            <small>User enter chat room</small>
          </Snack>
        </Divider>
      </Flex>
    )
  } else if (event.type === 'UNFOLLOW') {
    return (
      <Flex style={{ padding: '0 16px' }}>
        <Divider style={{ lineHeight: 1 }}>
          <Snack>
            <small>User leave chat room</small>
          </Snack>
        </Divider>
      </Flex>
    )
  } else {
    return null
  }
}

LineEventRender.propTypes = {
  event: customPT.CHAT_EVENT_PT,
  friendData: customPT.CHAT_PT,
  botId: PT.string,
}

export default LineEventRender
