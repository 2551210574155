import React from 'react'
import { obj2qs } from './utils'

const ByPass = ({ history, match }) => {
  React.useEffect(() => {
    history.push({
      pathname: '/p',
      search: obj2qs({
        destination: `/${match.params.botId || '73fbd23255'}/chat`,
        user_id: 'mu@mu.com',
        access_token:
          'eyJhbGciOiJIUzI1NiJ9.M2JlMjg4NTRjODAwMGJmOmJkNmNjYmExMDk5YWNhYw.zM7sTZA36WqtCKA_gFOzTlFPSrxp9Uiqgf-5g_jtMbM',
      }),
    })
  }, [])
  return null
}

export default ByPass
