import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AppLayouts from './layout'
import Home from './Home'
import Portal from './Portal'
import Logout from './Logout'
import PrivateRoute from './PrivateRoute'
import HeightController from './libs/HeightController'
import ByPass from './ByPass'
import ConnectFacebook from './layout/Mobile/ConnectFacebook'

const App = () => {
  return (
    <HeightController>
      <div className="full-height">
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/b/p" component={Portal} />
          <Route path="/logout" component={Logout} />
          <Route path="/mu/bypass" component={ByPass} />
          <Redirect from="/:botId/chat" to="/:botId/chat/All/list" exact />
          <PrivateRoute path="/:botId/chat/:platform" component={AppLayouts} />
          <PrivateRoute path="/:botId/connect-facebook" component={ConnectFacebook} />
        </Switch>
      </div>
    </HeightController>
  )
}

export default App
