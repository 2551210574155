import React from 'react'
import PT from 'prop-types'
import { Spin } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { withRouter } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'

import ChatItem from './ChatItem'
import ChatSidebarTop from './Top'
import { Flex } from '../../styled'
import NOTI_SOUND from '../../assets/sound/noti.mp3'

const ALL_CHATS = gql`
  query allChats($botId: ID!, $userId: String, $filters: ChatFilterInput, $page: PaginationInput) {
    allChats(bot_id: $botId, user_id: $userId, filters: $filters, page: $page) {
      pagination {
        size
        next
      }
      chats {
        id
        channel_id
        social_id
        full_name
        picture_url
        platform
        tags
        bc_data
        blocked
        creation
        status
        wisible {
          id
          url
          enabled
        }
        last_event {
          chat_id
          type
          timestamp
          source {
            type
            id
          }
          message
          postback
          read
        }
      }
    }
  }
`

const SUBSCRIBE_CHAT = gql`
  subscription onChatChanged($botId: ID!, $userId: String!) {
    onChatChanged(bot_id: $botId, user_id: $userId) {
      chat_id
      type
      timestamp
      read
      source {
        type
        id
      }
      message
      postback
    }
  }
`

const SUBSCRIBE_FRIEND = gql`
  subscription onFriendChanged($botId: ID!, $platform: Platform) {
    onFriendChanged(bot_id: $botId, platform: $platform)
  }
`

const READ_CHAT = gql`
  mutation readChat($chatId: ID!, $botId: ID!) {
    readChat(bot_id: $botId, chat_id: $chatId)
  }
`

class ChatSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      moreLoading: false,
      selectedId: props.defaultSelected,
    }
    this.scrollbar = React.createRef()
    this.unSubChat = () => {}
    this.unSubFriend = () => {}
  }

  handleItemClick = target => {
    const { location, history, useHistory, botId, readChat, platform } = this.props
    const newUrl = `/${botId}/chat/${platform}/${target.id}`
    this.setState({ selectedId: target.id }, () => {
      if (useHistory) {
        history.push({ pathname: newUrl, search: location.search })
      } else {
        history.replace({ pathname: newUrl, search: location.search })
      }
    })
    readChat({ variables: { botId, chatId: target.id } })
  }

  handleScroll = () => {
    const { hasMore } = this.props
    const { moreLoading } = this.state
    const scrollValue = this.scrollbar.current.getValues()
    if (scrollValue.top === 1 && !moreLoading && hasMore) {
      this.setState({ moreLoading: true })
      this.props.onLoadMore(() => this.setState({ moreLoading: false }))
    }
  }

  handleFilterChange = search => {
    const { onFilterChange } = this.props
    onFilterChange && onFilterChange(search)
  }

  componentDidMount() {
    this.unSubChat = this.props.subscribeChat(this.state.selectedId)
    this.unSubFriend = this.props.subscribeFriend(this.props.platform)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.platform !== this.props.platform) {
      this.unSubFriend()
      this.unSubFriend = this.props.subscribeFriend(this.props.platform)
    }
    if (prevProps.defaultSelected !== this.props.defaultSelected) {
      this.setState({ selectedId: this.props.defaultSelected })
    }
    if (prevState.selectedId !== this.state.selectedId) {
      this.unSubChat()
      this.unSubChat = this.props.subscribeChat(this.state.selectedId)
    }
  }

  componentWillUnmount() {
    this.unSubChat()
    this.unSubFriend()
  }

  render() {
    const { chatList, loading, filters, botId } = this.props
    const { selectedId, moreLoading } = this.state
    return (
      <React.Fragment>
        <ChatSidebarTop
          filters={filters}
          loading={loading}
          onFilterChange={this.handleFilterChange}
          botId={botId}
        />
        <Scrollbars
          ref={this.scrollbar}
          onScroll={this.handleScroll}
          style={{ height: 'calc(100% - 52px - 42px)', width: '100%', position: 'relative' }}
          autoHide
        >
          {loading && (
            <Flex
              style={{
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 100,
              }}
            >
              <Spin />
            </Flex>
          )}
          {chatList.map(c => (
            <ChatItem
              key={c.id}
              onItemClick={() => this.handleItemClick(c)}
              data={c}
              isSelected={c.id === selectedId}
            />
          ))}
          {moreLoading && (
            <Flex style={{ justifyContent: 'center', padding: 16 }}>
              <Spin />
            </Flex>
          )}
        </Scrollbars>
      </React.Fragment>
    )
  }
}

ChatSidebar.propTypes = {
  useHistory: PT.bool,
  platform: PT.string,
}

export default withRouter(props => {
  const { location, match, platform } = props
  const botId = match.params.botId
  const userId = localStorage.getItem('user_id')

  // get Friend ID of link without match params
  let defaultSelected = location.pathname.replace(match.url, '').replace('/', '')

  // search flow for test
  let [filters, setFilters] = React.useState({
    tags: [],
    status: undefined,
    search: '',
    t: moment().valueOf('x'),
    platform,
  })
  let [page, setPage] = React.useState({
    size: 100,
    start: '',
  })

  React.useEffect(() => {
    setFilters({
      ...filters,
      platform,
    })
  }, [platform])
  React.useEffect(() => {
    defaultSelected = location.pathname.replace(match.url, '').replace('/', '')
  }, [location])

  const [readChat] = useMutation(READ_CHAT, {
    // refetchQueries: [{ query: ALL_CHATS, variables: { botId, userId: user.email, filters } }],
  })
  const { data, loading, error, fetchMore, subscribeToMore } = useQuery(ALL_CHATS, {
    variables: {
      botId,
      userId,
      filters,
      page,
    },
  })

  const onFilterChange = item => {
    setFilters({ ...filters, ...item, t: moment().valueOf('x') })
    setPage({ size: 100, start: '' })
  }

  const playAlert = () => {
    const audio = new Audio(NOTI_SOUND)
    audio.play()
  }

  const notifyMessage = body => {
    playAlert()
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        new Notification('AIYA Chat App', { body })
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            notifyMessage(body)
          }
        })
      }
    }
  }

  if (error) {
    console.error(error.message)
  }

  let allChatsData = (data && data.allChats) || {}
  let { chats = [], pagination = {} } = allChatsData

  const loadmore = cb => {
    fetchMore({
      variables: { botId, page: { size: pagination.size, start: pagination.next } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev
        }
        const newAllChatsData = fetchMoreResult.allChats || {}
        cb && cb()
        return {
          ...prev,
          allChats: {
            ...prev.allChats,
            pagination: newAllChatsData.pagination,
            chats: [...prev.allChats.chats, ...newAllChatsData.chats],
          },
        }
      },
    })
  }

  const subscribeFriend = platform =>
    subscribeToMore({
      document: SUBSCRIBE_FRIEND,
      variables: { botId, platform: platform === 'All' ? undefined : platform },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const incomingFriend = subscriptionData.data.onFriendChanged || {}

        if (incomingFriend.op === 'CREATE') {
          return {
            ...prev,
            allChats: {
              ...prev.allChats,
              chats: [incomingFriend, ...prev.allChats.chats],
            },
          }
        }

        if (incomingFriend.op === 'UPDATE') {
          const updatedId = incomingFriend.id
          let tmp = [...prev.allChats.chats]
          const updatedIndex = tmp.findIndex(ch => ch.id === updatedId)
          tmp[updatedIndex] = {
            ...tmp[updatedIndex],
            ...incomingFriend,
            doctype: undefined,
            op: undefined,
          }

          return {
            ...prev,
            allChats: { ...prev.allChats, chats: tmp },
          }
        }

        return prev
      },
    })

  const subscribeChat = selected =>
    subscribeToMore({
      document: SUBSCRIBE_CHAT,
      variables: { botId, userId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev

        const incomingChat = subscriptionData.data.onChatChanged || {}
        const updatedIndex = (prev.allChats || {}).chats.findIndex(
          chat => chat.id === incomingChat.chat_id,
        )
        if (updatedIndex === -1) {
          return prev
        }

        if (incomingChat.read === false) {
          if (incomingChat.chat_id === selected) {
            readChat({ variables: { botId, chatId: selected } })
          } else {
            notifyMessage('You have a new message.')
          }
        }

        let tmp = [...prev.allChats.chats]
        let newLastEvent = { ...tmp[updatedIndex].last_event }

        Object.keys(incomingChat).forEach(k => {
          if (incomingChat[k] != null) {
            newLastEvent[k] = incomingChat[k]
          }
        })

        tmp[updatedIndex] = {
          ...tmp[updatedIndex],
          last_event: newLastEvent,
        }
        return { ...prev, allChats: { ...prev.allChats, chats: tmp } }
      },
    })

  const sortedChats = chats.sort((a, b) => {
    const aTime = (a.last_event || {}).timestamp || a.creation
    const bTime = (b.last_event || {}).timestamp || b.creation
    return bTime - aTime
  })

  return (
    <ChatSidebar
      {...props}
      loading={loading}
      botId={botId}
      defaultSelected={defaultSelected}
      filters={filters}
      chatList={sortedChats}
      readChat={readChat}
      subscribeFriend={subscribeFriend}
      subscribeChat={subscribeChat}
      onFilterChange={onFilterChange}
      onLoadMore={cb => loadmore(cb)}
      hasMore={!!pagination.next}
    />
  )
})
