import React from 'react'
import { qs2obj } from './utils/index'
import Loading from './components/Loading'

const Portal = ({ location, history }) => {
  const params = qs2obj(location.search)

  React.useEffect(() => {
    localStorage.setItem('user_id', params.user_id)
    localStorage.setItem('access_token', params.access_token)
    setTimeout(() => {
      // only for richmenu redirect
      history.replace(params.destination)
    }, 3000)
  })

  return <Loading />
}

export default Portal
