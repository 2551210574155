import React from 'react'
import PT from 'prop-types'
import { Select, Input, Button, Icon, Tooltip } from 'antd'

import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const ALL_TAGS = gql`
  query allTags($botId: ID!) {
    allTags(bot_id: $botId)
  }
`

const ChatSidebarTop = ({ loading, onFilterChange, filters, botId }) => {
  const isFiltered = filters.search || filters.status || filters.tags.length
  const [input, setInput] = React.useState(filters.search)
  const { data, loading: loadTags, error } = useQuery(ALL_TAGS, { variables: { botId } })

  if (error) {
    console.error(error)
  }

  const allTags = (data && data.allTags) || []

  const status = ['all', 'DEFAULT', 'FOLLOWED_UP', 'DONE', 'SPAM']
  const getStatusWord = key => {
    switch (key) {
      case 'DEFAULT':
        return 'ทั่วไป'
      case 'FOLLOWED_UP':
        return 'ต้องดำเนินการ'
      case 'DONE':
        return 'ดำเนินการแล้ว'
      case 'SPAM':
        return 'สแปม'
      default:
        return 'ทั้งหมด'
    }
  }
  const clearFilters = () => {
    setInput('')
    onFilterChange({
      search: '',
      status: undefined,
      tags: [],
    })
  }
  const handleStatusClick = key => {
    if (key === 'all') {
      onFilterChange({ status: undefined })
    } else {
      onFilterChange({ status: key })
    }
  }
  return (
    <div style={{ padding: '8px', borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: 8,
        }}
      >
        {/* <label style={{ marginRight: 8 }}>เลือกสถานะ</label> */}
        <Select
          style={{ width: 130 }}
          value={filters.status || 'all'}
          onChange={val => handleStatusClick(val)}
          loading={loading}
        >
          {status.map(s => (
            <Select.Option key={s}>{getStatusWord(s)}</Select.Option>
          ))}
        </Select>
        <Tooltip title="ล้างตัวกรอง">
          <Button
            style={{ padding: '0 8px', marginLeft: 8 }}
            onClick={clearFilters}
            type={isFiltered ? 'primary' : 'default'}
          >
            <Icon type="filter" theme={isFiltered ? 'filled' : 'outlined'} />
          </Button>
        </Tooltip>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Select
          style={{ width: 'calc(50% - 4px)' }}
          placeholder="ค้นหาตามแท็ก.."
          mode="multiple"
          loading={loading || loadTags}
          value={filters.tags}
          onChange={val => onFilterChange({ tags: val })}
        >
          {allTags.map(t => (
            <Select.Option key={t}>{t}</Select.Option>
          ))}
        </Select>
        <Input.Search
          value={input}
          onChange={e => setInput(e.target.value)}
          onSearch={() => onFilterChange({ search: input })}
          onPressEnter={() => onFilterChange({ search: input })}
          disabled={loading}
          style={{ width: 'calc(50% - 4px)' }}
          placeholder="ค้นหาเพื่อน"
        />
      </div>
    </div>
  )
}

ChatSidebarTop.propTypes = {
  style: PT.object,
  loading: PT.bool,
  onFilterChange: PT.func,
  filters: PT.shape({
    search: PT.string,
    tags: PT.arrayOf(PT.string),
    status: PT.oneOf(['DEFAULT', 'FOLLOWED_UP', 'DONE', 'SPAM']),
  }),
}

ChatSidebarTop.defaultProps = {
  onFilterChange: () => {},
}

export default ChatSidebarTop
