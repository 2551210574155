import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Icon, Button, Modal } from 'antd'

import AIYA_BOT from '../../assets/images/AIYA_Bot_Blank.jpg'
import AIYA_LOGO from '../../assets/images/aiya-logo-blue.png'
import ChatSidebar from '../../components/ChatSidebar'
import ImageRenderer from '../../components/ImageRenderer'
import BotContext from '../../context/Bot'
import { Flex } from '../../styled'
import PlatformSelector from './PlatformSelector'
import ChatContainer from './ChatContainer'

const DesktopLayout = ({ match, history, location }) => {
  const bot = React.useContext(BotContext)
  const botId = match.params.botId
  const platform = match.params.platform
  const chatId = location.pathname.split('/')[4]

  const openModalConfirm = () => {
    Modal.confirm({
      title: 'ออกจากระบบตอนนี้?',
      content: 'การออกจากระบบจาก chat.aiya.ai จะทำให้คุณออกจากระบบจาก manager.aiya.ai ด้วยเช่นกัน',
      okText: 'ใช่',
      cancelText: 'ไม่',
      okType: 'danger',
      onOk: () => {
        history.push('/logout')
      },
    })
  }

  const handlePlatformSelect = platform => {
    history.replace(`/${botId}/chat/${platform}/${chatId}`)
  }

  return (
    <div>
      <div
        style={{
          height: 60,
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          display: 'flex',
          width: '100vw',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px 0 32px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <ImageRenderer height={48} src={AIYA_LOGO} alt="logo" />
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 24 }}>
            <div
              style={{
                borderRadius: '50%',
                width: 36,
                height: 36,
                overflow: 'hidden',
                marginRight: 8,
              }}
            >
              <ImageRenderer
                src={bot.image || AIYA_BOT}
                alt="bot"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
            <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
              <span style={{ fontWeight: 500, color: 'rgba(38, 119, 180, 1)' }}>
                {bot.title || 'Bot Name'}
              </span>
              <span style={{ fontSize: '0.9em', fontWeight: 300, color: 'rgba(183, 183, 183, 1)' }}>
                @{bot.name || 'bot_id'}
              </span>
            </div>
          </div>
        </div>
        <Button onClick={openModalConfirm}>ออกจากระบบ</Button>
      </div>
      <Flex style={{ height: 'calc(100vh - 60px)' }}>
        <div style={{ width: 196, height: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.15)' }}>
          <PlatformSelector currPlatform={platform} selectPlatform={handlePlatformSelect} />
        </div>
        <div style={{ width: 360, height: '100%' }}>
          <ChatSidebar platform={platform} />
        </div>
        <div
          style={{
            width: 'calc(100% - 360px - 196px)',
            height: '100%',
            borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
          }}
        >
          <Switch>
            <Route
              path="/:botId/chat/:platform/list"
              render={() => (
                <Flex
                  style={{
                    justifyContent: 'center',
                    height: '100%',
                    fontSize: '12em',
                    color: 'rgba(0, 0, 0, 0.25)',
                  }}
                >
                  <Icon type="message" />
                </Flex>
              )}
            />
            <Route
              path="/:botId/chat/:platform/:chatId"
              render={props => <ChatContainer {...props} botId={botId} platform={platform} />}
            />
          </Switch>
        </div>
      </Flex>
    </div>
  )
}

export default DesktopLayout
